import React, { useState } from "react";
import { Grid, Card, Typography, Box, Stack } from "@mui/material";
import { FactCheck } from "@mui/icons-material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MemoryIcon from "@mui/icons-material/Memory";
import CachedIcon from "@mui/icons-material/Cached";
import ImageIcon from "@mui/icons-material/Image";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GridArrowDownwardIcon } from "@mui/x-data-grid";
const Artificialintl = () => {
    const [text, setText] = useState("");
    const maxCharacters = 2000;

    const handleTextChange = (e) => {
        setText(e.target.textContent);
    };

    return (
        <div>
            {" "}
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "125vh" }}
            >
                <div
                    className="card w-75 h-75 p-4  rounded "
                    style={{ backgroundColor: "#E5E5E5" }}
                >
                    <Typography variant="h4" component="p">
                        Hi there,{" "}
                        <span className="bg-blue-white-gradient">customer</span>
                    </Typography>
                    <Typography variant="h4" component="p">
                        What{" "}
                        <span className="bg-blue-white-gradient">
                            would you like to know?
                        </span>
                    </Typography>
                    <Typography variant="h6">
                        Use one of the most common prompts
                    </Typography>
                    <Typography variant="h6">
                        Or use your own to begin
                    </Typography>

                    <div className=" mt-3 ">
                        <Grid container>
                            <Grid
                                item
                                xs={2}
                                className="card bg-grey ml-2 my-4 px-3  py-2 border  text-primary cursor-pointer"
                                style={{
                                    position: "relative",
                                    minHeight: "150px",
                                }}
                            >
                                <div className="h7 font-weight-bold font-size-13">
                                    <strong>
                                        Write a to-do list for <br /> a personal
                                        project or task
                                    </strong>
                                </div>
                                <span
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        left: "10px",
                                    }}
                                >
                                    <FactCheck fontSize="medium" />
                                </span>
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                className="card m-4 p-2 bg-grey border  text-primary cursor-pointer"
                                style={{
                                    position: "relative",
                                    minHeight: "150px",
                                }}
                            >
                                <div className="h7  font-weight-bold font-size-13">
                                    <strong>
                                        Generate an email or <br /> reply to a
                                        job offer
                                    </strong>
                                </div>
                                <span
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        left: "10px",
                                    }}
                                >
                                    <MailOutlineIcon fontSize="medium" />
                                </span>
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                className="card  p-2  m-4  bg-grey border  text-primary cursor-pointer"
                                style={{
                                    position: "relative",
                                    minHeight: "150px",
                                }}
                            >
                                <div className="h7 font-weight-bold font-size-13">
                                    <strong>
                                        Summarise this article <br /> or text
                                        for me in one paragraph
                                    </strong>
                                </div>
                                <span
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        left: "10px",
                                    }}
                                >
                                    <ChatBubbleOutlineIcon fontSize="medium" />
                                </span>
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                className="card m-4 p-2 bg-grey border  text-primary cursor-pointer"
                                style={{
                                    position: "relative",
                                    minHeight: "150px",
                                }}
                            >
                                <div className="h7  font-weight-bold font-size-13">
                                    <strong>
                                        How does AI work in a <br /> technical
                                        capacity
                                    </strong>
                                </div>
                                <span
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        left: "10px",
                                    }}
                                >
                                    <MemoryIcon fontSize="medium" />
                                </span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="text-muted  mx-2 ">
                        <CachedIcon />
                        <span className=" m-1">Refresh Prompts</span>
                    </div>
                    <Grid item>
                        <Box className="w-100 h-75 bg-white rounded-0">
                            <div
                                className="py-2  px-3 mt-3"
                                contentEditable={true}
                                onInput={handleTextChange}
                                style={{ marginBottom: "70px" }}
                            >
                                <div className=" d-flex justify-content-between ">
                                    <div className=" h5 mt-2 font-size-12 ">
                                        Ask whatever you want...
                                    </div>
                                    <div className="align-items-center text-primary p-2 bg-grey rounded-pill ml-3">
                                        <LanguageOutlinedIcon />
                                        All Web <ArrowDropDownIcon />
                                    </div>
                                </div>
                            </div>
                            <div className=" d-flex justify-content-between mb-4 ">
                                <div className="ml-3 mb-4">
                                    <AddCircleIcon /> Add Attachment
                                    <span className="ml-3">
                                        <ImageIcon /> Use Image
                                    </span>
                                </div>
                                <div className="d-flex text-dark font-weight-bold font-size-12">
                                    {`${text.length}/${maxCharacters}`}
                                    <div
                                        className=" align-items-center text-white pb-1 bg-primary  mx-2 rounded"
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </div>
            </Grid>
        </div>
    );
};

export default Artificialintl;
