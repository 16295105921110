import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Box, Divider, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Select from "react-select";
import { FONT_STYLE_SELECT, GRID_STYLE } from "pages/Repair/CONSTANTS";
import { DataGrid } from "@mui/x-data-grid";
import { isEmpty } from "pages/PortfolioAndBundle/newCreatePortfolioData/utilities/textUtilities";
import { Warranty_Component_GetById_GET, Warranty_Cost_Coverage_Create_POST } from "services/CONSTANTS";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";

// const costCoverageDropdowns = [
//   { label: "Parts %", value: "parts" },
//   { label: "Labour %", value: "labour" },
//   { label: "Misc %", value: "expenses" },
// ];

const CoverageTypeOptions = [
  { label: "Only Parts", value: "ONLY_PARTS" },
  { label: "Only Labour", value: "ONLY_LABOUR" },
  { label: "Parts & Labour", value: "PARTS_AND_LABOUR" },
  { label: "All", value: "ALL" },
];

const WarrantyCoverageView = ({
  show,
  hideModal,
  selecteYearlyWarranty,
  yearWarrantyData,
  setYearWarrantyData,
  yearlyWarrantyId,
  warrantyCoverageIds,
  setWarrantyCoverageIds,
  handleSnack,
}) => {
  const [tabValue, setTabValue] = useState("warrantyCoverage");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [componentRecords, setComponentRecords] = useState([]);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [editCoverage, setEditCoverage] = useState(false);
  const [coverageLoading, setCoverageLoading] = useState(false);

  const [coverageType, setCoverageType] = useState("");
  const [covereageValues, setCovereageValues] = useState({
    partsCovered: 0,
    labourCovered: 0,
    expensesCovered: 0,
    yearlyWarrantyId: yearlyWarrantyId,
  });

  useEffect(() => {
    if (selecteYearlyWarranty) {
      if (selecteYearlyWarranty?.costCoverageId) {
        getCostCoverageDetails(selecteYearlyWarranty?.costCoverageId);
      }

      if (selecteYearlyWarranty.componentIds && selecteYearlyWarranty.componentIds.length > 0) {
        getComponents(selecteYearlyWarranty.componentIds);
      }
    }
  }, [selecteYearlyWarranty]);

  // get the cost coverage details
  const getCostCoverageDetails = (costCoverageId) => {
    setEditCoverage(true);
    setCoverageLoading(true);
    const rUrl = `${Warranty_Cost_Coverage_Create_POST}/${costCoverageId}`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          const expensesCovered = responseData?.expensesCovered;
          const labourCovered = responseData?.labourCovered;
          const partsCovered = responseData?.partsCovered;

          if (partsCovered && labourCovered && expensesCovered) {
            setCoverageType({ label: "All", value: "ALL" });
          } else if (partsCovered && labourCovered && !expensesCovered) {
            setCoverageType({ label: "Parts & Labour", value: "PARTS_AND_LABOUR" });
          } else if (partsCovered && !labourCovered && !expensesCovered) {
            setCoverageType({ label: "Only Parts", value: "ONLY_PARTS" });
          } else if (!partsCovered && labourCovered && !expensesCovered) {
            setCoverageType({ label: "Only Labour", value: "ONLY_LABOUR" });
          }

          setCovereageValues(responseData);
          setCoverageLoading(false);
        } else {
          setCoverageLoading(false);
        }
      },
      (error) => {
        setCoverageLoading(false);
      }
    );
  };

  //
  const getComponents = (componentIds) => {
    const rUrl = `${Warranty_Component_GetById_GET}/by-ids?` + componentIds.map((id) => `ids=${id}`).join("&");
    callGetApi(rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        setComponentRecords(responseData);
      } else {
      }
    });
  };

  console.log("selecteYearlyWarranty :::: ", selecteYearlyWarranty);

  const handleSelectCoverageType = (e) => {
    setCoverageType(e);
    setCovereageValues({
      partsCovered: 0,
      labourCovered: 0,
      expensesCovered: 0,
      yearlyWarrantyId: yearlyWarrantyId,
    });
  };

  const columns = [
    {
      field: "partNumber",
      headerName: "Part#",
      flex: 1,
      width: 80,
    },
    {
      field: "family",
      headerName: "Family",
      flex: 1,
      width: 80,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      width: 80,
    },
    {
      field: "serialNumber",
      headerName: "Serial#",
      flex: 1,
      width: 80,
    },
    {
      field: "warrantyType",
      headerName: "Warranty",
      flex: 1,
      width: 80,
    },
    {
      field: "warrantyCategory",
      headerName: "Category",
      flex: 1,
      width: 80,
    },
    {
      field: "machineSerialNumber",
      headerName: "Machine Serial#",
      flex: 1,
      width: 80,
    },
    {
      field: "warrantyStartDate",
      headerName: "Warranty Start",
      flex: 1,
      width: 80,
    },
    {
      field: "warrantyEndDate",
      headerName: "Warranty End",
      flex: 1,
      width: 80,
    },
  ];

  const handleSaveCostCoverageDetails = () => {
    const rUrl = `${Warranty_Cost_Coverage_Create_POST}`;
    const rObj = {
      labourCovered: covereageValues.labourCovered,
      partsCovered: covereageValues.partsCovered,
      expensesCovered: covereageValues.expensesCovered,
      yearlyWarrantyId: yearlyWarrantyId,
    };

    if (yearWarrantyData?.costCoverageId) {
      callPutApi(null, `${rUrl}/${yearWarrantyData?.costCoverageId}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          setEditCoverage(true);
          handleSnack("success", "Cost Coverage Updated Successfully");
        }
      });
    } else {
      callPostApi(null, `${rUrl}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          const _yearWarrantyData = { ...yearWarrantyData, costCoverageId: responseData?.costCoverageId };
          setYearWarrantyData(_yearWarrantyData);
          handleSnack("success", "Cost Coverage Created Successfully");
          setEditCoverage(true);

          // setWarrantyCoverageIds({
          //   ...warrantyCoverageIds,
          //   [yearlyWarrantyId]: responseData.costCoverageId,
          // });
        }
      });
    }
    // if (!isEmpty(warrantyCoverageIds[yearlyWarrantyId])) {
    //   callPutApi(null, `${rUrl}/${warrantyCoverageIds[yearlyWarrantyId]}`, rObj, (response) => {
    //     if (response.status === API_SUCCESS) {
    //       handleSnack("success", "Cost Coverage Updated Successfully");
    //     }
    //   });
    // } else {
    //   callPostApi(null, `${rUrl}`, rObj, (response) => {
    //     if (response.status === API_SUCCESS) {
    //       const responseData = response.data;
    //       const _yearWarrantyData = { ...yearWarrantyData, costCoverageId: responseData?.costCoverageId };
    //       setYearWarrantyData(_yearWarrantyData);
    //       handleSnack("success", "Cost Coverage Created Successfully");
    //       setWarrantyCoverageIds({
    //         ...warrantyCoverageIds,
    //         [yearlyWarrantyId]: responseData.costCoverageId,
    //       });
    //     }
    //   });
    // }
  };

  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        <Grid item xs={12}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 1, marginInline: 5 }}>
              <TabList className="" onChange={(e, value) => setTabValue(value)}>
                <Tab label="Warranty Coverage" value={"warrantyCoverage"} className="heading-tabs" />
                <Tab label="Cost Coverage" value={"costCoverage"} className="heading-tabs" />
              </TabList>
            </Box>
            <TabPanel value="warrantyCoverage">
              <Box sx={{ height: 500, marginBottom: 8, marginInline: 2 }}>
                <DataGrid
                  loading={loading}
                  sx={GRID_STYLE}
                  getRowId={(row) => row.componentId}
                  page={page}
                  pageSize={pageSize}
                  //   onPageChange={(newPage) =>
                  //     fetchPartClassBRecords(newPage, partClassBPageSize)
                  //   }
                  //   onPageSizeChange={(newPageSize) =>
                  //     fetchPartClassBRecords(partClassBPageNo, newPageSize)
                  //   }
                  rows={componentRecords}
                  columns={columns}
                  rowsPerPageOptions={[10, 20, 50]}
                  paginationMode="server"
                  rowCount={totalRecords}
                  checkboxSelection={true}
                  keepNonExistentRowsSelected
                  onSelectionModelChange={(newRowSelectionModel) => setRowSelectionModel(newRowSelectionModel)}
                  selectionModel={rowSelectionModel}
                />
              </Box>
            </TabPanel>
            <TabPanel value="costCoverage">
              {editCoverage ? (
                <>
                  <div className="row" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary" onClick={() => setEditCoverage(false)}>
                      Edit
                    </button>
                  </div>
                  <div className="row">
                    <ReadOnlyField
                      label="COVERAGE TYPE"
                      value={coverageType?.label || coverageType || "NA"}
                      className="col-lg-12 col-md-12 col-sm-12 col-12"
                    />
                  </div>
                  <Divider sx={{ marginBottom: 4 }} />
                  <div className="row">
                    <ReadOnlyField label="PARTS COVERED" value={covereageValues.partsCovered} className="col-lg-4 col-md-4 col-sm-4 col-12" />
                    <ReadOnlyField label="LABOUR COVERED" value={covereageValues.labourCovered} className="col-lg-4 col-md-4 col-sm-4 col-12" />
                    <ReadOnlyField label="EXPENSES COVERED" value={covereageValues.expensesCovered} className="col-lg-4 col-md-4 col-sm-4 col-12" />
                  </div>
                  <div className="row mt-3" style={{ justifyContent: "right" }}>
                    <button type="button" className="btn bg-primary text-white" onClick={hideModal}>
                      Close
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="row input-fields">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-14 font-weight-500">Select Coverage Type</label>
                        <Select
                          className="text-primary"
                          options={CoverageTypeOptions}
                          placeholder="Select Coverage Type"
                          value={coverageType}
                          onChange={handleSelectCoverageType}
                          styles={FONT_STYLE_SELECT}
                        />
                      </div>
                    </div>
                  </div>
                  <Divider sx={{ marginBottom: 4 }} />
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                      <label className="text-light-dark font-size-14 font-weight-500">Parts Covered</label>
                      <div className=" d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <span className="hours-div warranty-Covereage-div">Parts %</span>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          placeholder="Enter value (%)"
                          name="recommendedValue"
                          disabled={coverageType === "" || coverageType?.value === "ONLY_LABOUR" ? true : false}
                          readOnly={coverageType === "" || coverageType?.value === "ONLY_LABOUR" ? true : false}
                          value={covereageValues.partsCovered}
                          onChange={(e) => setCovereageValues({ ...covereageValues, partsCovered: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                      <label className="text-light-dark font-size-14 font-weight-500">Labour Covered</label>
                      <div className=" d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <span className="hours-div warranty-Covereage-div">Labour %</span>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          placeholder="Enter value (%)"
                          name="recommendedValue"
                          disabled={coverageType === "" || coverageType?.value === "ONLY_PARTS" ? true : false}
                          readOnly={coverageType === "" || coverageType?.value === "ONLY_PARTS" ? true : false}
                          value={covereageValues.labourCovered}
                          onChange={(e) => setCovereageValues({ ...covereageValues, labourCovered: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                      <label className="text-light-dark font-size-14 font-weight-500">Expenses Covered</label>
                      <div className=" d-flex form-control-date" style={{ overflow: "hidden" }}>
                        <span className="hours-div warranty-Covereage-div">Expenses %</span>
                        <input
                          type="number"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          placeholder="Enter value (%)"
                          name="recommendedValue"
                          disabled={coverageType?.value === "ALL" ? false : true}
                          readOnly={coverageType?.value === "ALL" ? false : true}
                          value={covereageValues.expensesCovered}
                          onChange={(e) => setCovereageValues({ ...covereageValues, expensesCovered: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3" style={{ justifyContent: "right" }}>
                    <button type="button" className="btn btn-light bg-primary text-white" onClick={handleSaveCostCoverageDetails}>
                      Save
                    </button>
                  </div>
                </>
              )}
            </TabPanel>
          </TabContext>
        </Grid>
      </Modal.Body>
    </Modal>
  );
};

export default WarrantyCoverageView;
