import React, { useCallback, useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";

import { Modal } from "react-bootstrap";

import { GRID_STYLE } from "pages/Common/constants";
import { API_SUCCESS } from "services/ResponseCode";
import { getLabourPrice } from "services/searchServices";

const HoursOnJobModal = ({ show, hideModal, jobHoursRecords = [], setJobHoursRecords }) => {
    const [rowModesModel, setRowModesModel] = useState({});

    // add new row
    const handleAddNewRow = () => {
        const id = jobHoursRecords.length + 1;
        const _jobHoursRecords = [...jobHoursRecords];
        _jobHoursRecords.push({
            id: id,
            chargeCode: "",
            // extraApprovedTime: "",
            totalTime: "",
            unitOfMeasure: "HOURS",
            unitPrice: "",
            totalPrice: 0,
            comments: "",
        });
        setJobHoursRecords(_jobHoursRecords);
    };

    const getUnitPrice = async (row) => {
        if (row.chargeCode && row.jobCode) {
            const res = await getLabourPrice(`chargeCode:${row.chargeCode} %26%26 serviceType:${row.jobCode}`);
            if (res.status === API_SUCCESS) {
                const responseData = res.data;
                return responseData[0].price;
            }
            return 0;
        }
        return 0;
    };

    const getTotalTime = (row) => {
        if (row.unitOfMeasure === "MINUTES") {
            return row.totalTime ? (row.totalTime / 60).toFixed(2) : 0;
        }
        return row.totalTime || 0;
    };

    const jobHoursColumns = [
        {
            field: "chargeCode",
            headerName: "Charge Code",
            flex: 1,
            editable: true,
            type: "singleSelect",
            valueOptions: [
                { label: "Minimum service call", value: "MINIMUM_SERVICE_CALL" },
                { label: "Additional items", value: "ADDITIONAL_ITEMS" },
                { label: "Non-Scheduled additional items", value: "NON_SCHEDULED_ADDITIONAL_ITEMS" },
                { label: "Extra Approved Time", value: "EXTRA_APPROVED_TIME" },
            ],
            valueFormatter: ({ api, field, value }) => {
                const options = api.getColumn(field).valueOptions;
                const option = options.find(({ value: optionValue }) => value === optionValue);

                if (option) return option.label;
            },
        },
        {
            field: "jobCode",
            headerName: "Job Code",
            flex: 1,
            editable: true,
            type: "singleSelect",
            valueOptions: [
                { label: "Ground - clean", value: "GROUND_CLEAN" },
                { label: "Adjust", value: "ADJUST" },
                { label: "Inspect", value: "INSPECT" },
            ],
            valueFormatter: ({ api, field, value }) => {
                const options = api.getColumn(field).valueOptions;
                const option = options.find(({ value: optionValue }) => value === optionValue);

                if (option) return option.label;
            },
        },
        {
            field: "totalTime",
            headerName: "Total Time",
            flex: 1,
            editable: true,
        },
        {
            field: "unitOfMeasure",
            headerName: "Unit of Measure",
            flex: 1,
            editable: true,
            type: "singleSelect",
            valueOptions: [
                { label: "Hours", value: "HOURS" },
                { label: "Minutes", value: "MINUTES" },
            ],
            valueFormatter: ({ api, field, value }) => {
                const options = api.getColumn(field).valueOptions;
                const option = options.find(({ value: optionValue }) => value === optionValue);

                if (option) return option.label;
            },
        },
        {
            field: "unitPrice",
            headerName: "Unit Price",
            flex: 1,
            // editable: true,
        },
        {
            field: "totalPrice",
            headerName: "Total Price",
            flex: 1,
            // editable: true,
        },
        {
            field: "comments",
            headerName: "Coments",
            flex: 1,
            editable: true,
        },
        {
            field: "action",
            type: "actions",
            headerName: "Action",
            //   width: 150,
            flex: 1,
            cellClassName: "actions",
            getActions: ({ row }) => {
                const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Save">
                                    <SaveIcon />
                                </Tooltip>
                            }
                            label="Save"
                            onClick={handleSaveClick(row.id)}
                        />,
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Cancel">
                                    <CancelIcon />
                                </Tooltip>
                            }
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(row.id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Edit">
                                <EditOutlinedIcon />
                            </Tooltip>
                        }
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(row.id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Remove">
                                <DeleteIcon />
                            </Tooltip>
                        }
                        label="Delete"
                        onClick={handleDeleteClick(row.id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    // row edit start
    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    // row edit stop
    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    // change table row edit|delete mode change
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    // click on row edit button
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    // click on row save button
    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    // click on row delete button
    const handleDeleteClick = (id) => () => {
        // if (relatedPartsId) {
        //     callDeleteApi(null, `${RELATED_PARTS_MASTER_URL}/${relatedPartsId}`, (response) => {
        //         if (response.status === API_SUCCESS) {
        //             setJobHoursRecords(jobHoursRecords.filter((row) => row.relatedPartsId !== relatedPartsId));
        //         }
        //     });
        // } else {
        // }
        setJobHoursRecords(jobHoursRecords.filter((row) => row.id !== id));
    };

    // click on row cancel button
    const handleCancelClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

        const editedRow = jobHoursRecords.find((row) => row.id === id);
        if (editedRow.isNew) {
            setJobHoursRecords(jobHoursRecords.filter((row) => row.id !== id));
        }
    };

    // row update process >> put api call
    const processRowUpdate = useCallback(
        async (newRow, oldRow) =>
            new Promise(async (resolve, reject) => {
                const updatedRow = { ...newRow, isNew: true };

                const _unitPrice = await getUnitPrice(newRow);
                const _totalPrice = getTotalTime(newRow) * _unitPrice;
                setJobHoursRecords(
                    jobHoursRecords.map((row) =>
                        row.id === updatedRow.id ? { ...updatedRow, isNew: undefined, unitPrice: _unitPrice, totalPrice: _totalPrice } : row
                    )
                );

                resolve({ ...updatedRow, isNew: undefined, unitPrice: _unitPrice, totalPrice: _totalPrice });
            }),
        [jobHoursRecords]
    );

    return (
        <Modal show={show} onHide={hideModal} size="xl">
            <Modal.Body>
                <div className="card border px-3 py-2">
                    <div className={`row d-flex justify-content-between align-items-center py-2`}>
                        <h4 className="mx-3">Add Hours</h4>
                        <div className="row mx-2 mb-1" style={{ justifyContent: "right" }}>
                            <button type="button" className="btn btn-primary " onClick={handleAddNewRow}>
                                + Add New
                            </button>
                        </div>
                    </div>
                    <DataGrid
                        sx={GRID_STYLE}
                        getRowId={(row) => row.id}
                        rows={jobHoursRecords}
                        autoHeight
                        columns={jobHoursColumns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        experimentalFeatures={{ newEditingApi: true }}
                        onProcessRowUpdateError={(error) => console.log(error)}
                        processRowUpdate={processRowUpdate}
                    />

                    <div className={`row my-2 `} style={{ justifyContent: "right" }}>
                        <button type="button" className="btn btn-border-primary mx-2" onClick={hideModal}>
                            Back
                        </button>
                        <button type="button" className="btn btn-light bg-primary text-white" onClick={hideModal}>
                            Save & Close
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default HoursOnJobModal;
